import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "key": "reusable",
  "langKey": "en",
  "title": "business-perfect-software-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`The perfect military government software:`}</h1>
    <p>{`In the 80's the US military spent over $5,000,000 (and back in THOSE days, $5M USD was a LOT of money.) writing a program that THEY agreed could be written for $5,000. But they spent the $5M to PROVE that a PERFECT computer design and a PERFECT computer software was possible, that would run 1st time with no bugs. `}</p>
    <p>{`(Remember, this is a TRUE story.)`}</p>
    <p>{`They called the press for a big gala event for the 1st time they were going to run the software.`}</p>
    <p>{`The software was supposed to shoot a laser and hit a 25cent sized piece (about 3cm around) on the shuttle craft while it sped by.`}</p>
    <p>{`They timed it for when the shuttle craft was reflecting the light of the sun so that the press could see where the shuttlecraft was.`}</p>
    <p>{`They ran the program. The shuttlecraft was straight ahead. The laser gun started to move …. then it shot about 90 degrees to the right. `}</p>
    <p>{`LITERALLY missing the shuttlecraft by a million miles.`}</p>
    <p>{`They called the press together the next day. Turns out: The programmers were humans, so THEY of course thought in MILES. But the SCIENTISTS had this really weird way of thinking: They used some obscure measurement system known as 'Kilometers'`}</p>
    <p>{`They made a quick change to the software to change it to use KM's as the measurement value, called the press the next day (only a handful showed up) and hit the quarter sized target on the shuttlecraft.`}</p>
    <p>{`The point: By spending ONLY $4,995,000 on a $5,000 program. They accomplished exactly NOTHING. The iterative process would have:`}</p>
    <ul>
      <li parentName="ul">{`cost $4,995,000 less`}</li>
      <li parentName="ul">{`cost 99.9% less`}</li>
      <li parentName="ul">{`would have been completed SOONER (2 years sooner fwiw)`}</li>
    </ul>
    <p>{`The ONLY thing this did was to save the military money in the future.`}</p>
    <p>{`Oops, sorry, no hammers cost $10,000 in the military. They didn't even benefit from their mistake by learning from it. `}</p>
    <h1>{`The perfect bank software:`}</h1>
    <p>{`The other story, around 1986. A bank spend an obscene amount of money to write 'the perfect' software so that they could switch over to the new software without running in parallel.`}</p>
    <p>{`They argued that running in parallel was 'too expensive' it was better to 'do it right the first time.'`}</p>
    <p>{`They wrote for months articles telling people like me that we were failure cases waiting to happen and that we should program' perfectly' like them instead of iterative - or any of the steps in between.`}</p>
    <p>{`After writing all these inflammatory articles and getting "letters to the editor" warning customers of the bank to panic withdraw their money before the trigger.`}</p>
    <p>{`The public TRUSTED the bank and did not withdraw the money.`}</p>
    <p>{`There were some unknown, possibly very small number of very small bugs.`}</p>
    <p>{`The bank was bankrupt 3 months after the trigger date.`}</p>
    <p>{`We never did find out WHAT the bugs were that they missed - they stopped writing articles right after the trigger date when everything started to fail.`}</p>
    <h1>{`Concluding thoughts:`}</h1>
    <p>{`There have been many other sad stories through the years. Such as when Google thought in the mid 2010's that "greying out a button is a good way to tell some they can click on it" (Material design) or Microsoft's failed Windows 8 (We took away all features that 20% of people or fewer used. Oops - that means we ticked off EVERY user in the world because EVERY user uses at least ONE feature that 80% of the rest don't. And "We spent millions of dollars on focus groups with NEW people who have never used Microsoft Windows before LOVED Windows 8 over Windows 7", and concluded without spending anything: so our huge installed base is ALSO going to love this completely new UI that is missing most of the features of the old one.)`}</p>
    <p>{`My point is: the iterative way can, at times, be frustrating, especially for the designers and programmers who have to make adjustments.`}</p>
    <p>{`But it is the BEST way I have found to write good quality software. In the 44 years I have been running software projects, starting in 1976 (a kid in high school - it might have been up to 3 years earlier, so I'm going with the conservative guess of 1976) and continuing to 2020 as of writing. Again, at time of writing … that's longer than most people on my team have been alive!`}</p>
    <p>{`Would I use the iterative way for writing a heart surgery application?`}</p>
    <p>{`Would I use the iterative way for writing eye surgery?`}</p>
    <p>{`No, but I have also decided to NEVER write software where a bug can cause permanent human suffering. But then I ALSO wouldn't trust someone who said "you are the first human we are using this on - and we wrote perfect code the 1st time so we didn't need to test it on any animals either."`}</p>
    <p>{`Am I saying the iterative way solves all problems? No! We write tests BEFORE we write code. We write unit tests, we have humans test - humans who are hired explicitly to do this as their job, we have beta tests, we roll out major changes slowly to customers and dozens of other things to try to get the best quality/cost ratio we can. But the iterative way, despite its many flaws, in the best way for most programming that we have found.`}</p>
    <h1>{`Are some programmers perfect?`}</h1>
    <p>{`On the other hand … some programmers are just really good.`}</p>
    <p>{`This one I BELIEVE is a true story, at the time I was told it, it had happened 'just months ago', it was published in a 'large format' print computer business magazine (11x17") in the 1980's.`}</p>
    <p>{`The military asked for a program that would fire an ICBM into a silo.`}<br parentName="p"></br>{`
`}{`The brass expected the pink.`}<br parentName="p"></br>{`
`}{`The programmer did the gold.  `}</p>
    <p><img parentName="p" {...{
        "src": "/files/business-perfect-software.png",
        "alt": "Perfect software - in a silo"
      }}></img></p>
    <p>{`Now … imagine how this looked in real life:`}</p>
    <p>{`The brass were all sitting in their stand, watching the (non-nuke, non-explosive) ICBM coming along. Then it PASSED the silo in front them. They all panicked. Then it did the loop, and went INTO the silo.`}</p>
    <p>{`When he was pulled up for a court-marshal, the programmer 'innocently' said that he was told to fly the ICBM 'into' the silo, and that had he done it the pink way, it would not have met the definition of the assignment. He also said there wasn't enough room to go up BEFORE the silo because it was fired too close (green line).`}</p>
    <p>{`For my ESL friends, 'into' can mean either of the pink or gold line definitions. The programmer chose to take it to mean fly  fully into the silo to the bottom (gold line), not into as in 'the car ran into a person' (pink line). Also notice that "in to" could not have been used, it had to be worded "into". To distinguish between the pink (simplest 'into') and the gold (most specific and therefore arguably the most accurate 'into') the assignment would have needed additional words to clarify, such as "Use the most direct line of attack to fly the ICBM into the far wall of the silo" or, if they had wanted what the programmer did "Fly the ICBM into the silo as deep as possible, ideally hitting the bottom".`}</p>
    <p>{`He wasn't court marshalled, but everyone knew that he had done it for show, and that he fully understood the pink was the way intended and that he knew the brass was going to panic as it flew past in front of them.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      